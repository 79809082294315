import React from 'react';

import { Button } from 'components/buttons/Button';
import { Loading } from 'components/loadings/Loading';

import { ErrorMessageBox } from '../common/ErrorMessageBox';
import InputText from '../common/InputText';
import { TextContentBox } from '../common/TextContentBox';

const VerifyPhoneNumberView = ({
    code,
    errorMessage,
    loading,
    onClearNumber,
    onCodeChange,
    onVerifyCode,
    phoneNumber,
}) => {
    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            onVerifyCode();
        }
    };
    return (
        <div className="loginActionsColumn tw-relative">
            <div className="loginActions twoFactorAuthentication">
                <div className="twoFactorContentContainer">
                    <TextContentBox
                        title="Two-factor Authentication"
                        paragraph1="Please enter the code you have received below. If you do not receive a code, please make sure you have entered a valid phone number."
                    />
                    <p className="tw-mt-12">
                        Code sent to: {phoneNumber} -
                        <button
                            onClick={onClearNumber}
                            className="tw-bg-transparent w-text-primary dark:tw-text-primary-dark hover:tw-text-primary-hover dark:hover:tw-text-primary-dark-hover"
                        >
                            Change Number
                        </button>
                    </p>
                    <div className="twoFactorInputContainer">
                        <InputText
                            autoFocus={true}
                            label="Code"
                            onChange={onCodeChange}
                            onKeyPress={onKeyPress}
                            placeholder="6-digit code"
                            type="number"
                            value={code}
                        />
                    </div>
                    <Button onClick={onVerifyCode} disabled={loading} text="VERIFY" />
                    {errorMessage && (
                        <div className="tw-mb-12">
                            <ErrorMessageBox errorMessage={errorMessage} />
                        </div>
                    )}
                    {loading && (
                        <div className="tw-absolute -tw-bottom-[130px] tw-w-full">
                            <div className="loading-wrapper">
                                <Loading />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VerifyPhoneNumberView;
