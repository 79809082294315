import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { SsoProviderModel } from 'types/sso/sso';

import { Loading } from 'components/loadings/Loading';

import { transformToCamelCase } from 'helpers/JsonFunctions';

import { ErrorMessageBox } from '../common/ErrorMessageBox';
import { TextContentBox } from '../common/TextContentBox';

import LoginForm from './LoginForm';
import LoginProvidersForm from './LoginProvidersForm';
import SsoProviders from './SsoProviders';

const LoginView = ({
    browser,
    email,
    errorMessage,
    getLoginProviders,
    loading,
    loginFailed,
    loginProvidersLoaded,
    onEmailChange,
    onPasswordChange,
    onSignIn,
    onSwitchAccount,
    password,
    showPassword,
    ssoProviders,
    publicSsoProviders,
}) => {
    // Convert HTML-encoded publicSsoProviders to typescript array
    const jsonString = new DOMParser().parseFromString(publicSsoProviders as string, 'text/html')
        .documentElement.textContent;
    const publicSsoProviderList = Object.values(
        transformToCamelCase(JSON.parse(jsonString) as SsoProviderModel[]),
    );

    const header = loginProvidersLoaded ? 'Welcome' : 'Sign in to your account';

    useEffect(() => {
        const hashUrl = window.location.hash;
        if (hashUrl !== '#/') {
            localStorage.setItem('salesscreen.loginRedirectHash', hashUrl);
        } else {
            localStorage.setItem('salesscreen.loginRedirectHash', '');
        }
    }, []);

    return (
        <div className="loginActionsColumn">
            <div className="loginActions" id="login-frontpage">
                <div className="tw-mb-16">
                    <TextContentBox
                        title={header}
                        paragraph1={loginProvidersLoaded ? email : null}
                    />
                </div>
                {publicSsoProviderList?.length > 0 && !loginProvidersLoaded && !showPassword && (
                    <SsoProviders ssoProviders={publicSsoProviderList} displayAlternativeInput />
                )}
                {!loginProvidersLoaded && (
                    <LoginProvidersForm
                        browser={browser}
                        email={email}
                        getLoginProviders={getLoginProviders}
                        loading={loading}
                        loginFailed={loginFailed}
                        onEmailChange={onEmailChange}
                    />
                )}
                {showPassword && (
                    <LoginForm
                        browser={browser}
                        email={email}
                        loading={loading}
                        loginFailed={loginFailed}
                        onPasswordChange={onPasswordChange}
                        onSignIn={onSignIn}
                        password={password}
                    />
                )}
                {errorMessage && <ErrorMessageBox errorMessage={errorMessage} />}
                {loginProvidersLoaded && ssoProviders?.length > 0 && !loginFailed && !loading && (
                    <SsoProviders
                        ssoProviders={ssoProviders}
                        displayAlternativeInput={showPassword}
                        displayAlternativeInputAbove
                    />
                )}
                <div className="tw-flex tw-flex-col tw-gap-12 tw-mt-32">
                    {loginProvidersLoaded && (
                        <button
                            className="tw-bg-transparent tw-border-none w-text-primary dark:tw-text-primary-dark hover:tw-text-primary-hover dark:hover:tw-text-primary-dark-hover tw-w-full tw-inline-block tw-text-center hover:tw-underline tw-text-m"
                            onClick={onSwitchAccount}
                        >
                            Sign in with a different account
                        </button>
                    )}
                    <Link
                        className="w-text-primary dark:tw-text-primary-dark hover:tw-text-primary-hover dark:hover:tw-text-primary-dark-hover tw-w-full tw-inline-block tw-text-center hover:tw-underline tw-text-m"
                        to="/forgot-password"
                    >
                        Forgot your password?
                    </Link>
                </div>

                {loading && (
                    <div className="loading-wrapper -tw-bottom-[100px]">
                        <Loading />
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoginView;
