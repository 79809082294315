import React from 'react';

import { blobUrl } from 'constants/Common';

import { Button } from 'components/buttons/Button';
import { Icon } from 'components/icons/Icon';
import { Loading } from 'components/loadings/Loading';

import { ErrorMessageBox } from '../common/ErrorMessageBox';
import InputPassword from '../common/InputPassword';
import { SubmitButton } from '../common/SubmitButton';

type ResetPasswordProps = {
    confirmPassword: string;
    errorMessage: string;
    isFirstTimeUser: boolean;
    loading: boolean;
    newPassword: string;
    onConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement>;
    onContinueToSalesScreen: React.FormEventHandler<HTMLInputElement>;
    onNewPasswordChange: React.ChangeEventHandler<HTMLInputElement>;
    onResetPassword: React.MouseEventHandler<HTMLInputElement>;
    onResetPasswordForm: React.MouseEventHandler<HTMLInputElement>;
    requireTwoFactor: boolean;
    resetPasswordBreachWarning: boolean;
    resetPasswordFailed: boolean;
    resetPasswordSuccess: boolean;
};

const fromMobile = () => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
        return true;
    }

    return false;
};

const ResetPassword = ({
    confirmPassword,
    errorMessage,
    isFirstTimeUser,
    loading,
    newPassword,
    onConfirmPasswordChange,
    onContinueToSalesScreen,
    onNewPasswordChange,
    onResetPassword,
    onResetPasswordForm,
    requireTwoFactor,
    resetPasswordBreachWarning,
    resetPasswordFailed,
    resetPasswordSuccess,
}: ResetPasswordProps) => {
    const inputBorderClass = [
        resetPasswordFailed ? 'warning' : '',
        resetPasswordSuccess ? 'disabled' : '',
    ].join(' ');
    return (
        <div className="tw-mt-16">
            <form>
                <input className="hidden" />
                <input type="password" className="hidden" />

                {resetPasswordSuccess ? (
                    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-24 tw-border tw-rounded tw-my-24">
                        <img
                            src={`${blobUrl}/login/password-confirmed.png`}
                            className="tw-max-w-[200px] tw-my-12"
                        />
                        <div className="tw-flex tw-items-center tw-gap-12 tw-mb-24 tw-bg-neutral tw-p-12 tw-border tw-rounded">
                            <div className="tw-p-4 tw-rounded tw-bg-success tw-text-neutral-inverse">
                                <Icon icon="check-small" size="small" />
                            </div>
                            {isFirstTimeUser
                                ? 'Your password has been set'
                                : 'Your password has been updated'}
                        </div>
                        {!requireTwoFactor && fromMobile() ? (
                            <a
                                href="salesscreen://login?skipLandingPage=true"
                                className="tw-flex tw-items-center tw-px-12 tw-py-8 tw-border tw-rounded tw-gap-4 tw-bg-primary tw-text-neutral-inverse active:tw-bg-primary-active"
                            >
                                <Icon icon="mobile-filled" />
                                Sign in from Mobile App
                            </a>
                        ) : (
                            <SubmitButton
                                onClick={onContinueToSalesScreen}
                                value="Continue to SalesScreen"
                            />
                        )}
                    </div>
                ) : resetPasswordBreachWarning ? (
                    <>
                        <ErrorMessageBox
                            errorMessage="This password has previously been exposed in a known data breach, and should not be considered secure! We strongly encourage you to use another password."
                            variant="warning"
                        />
                        <div className="tw-mt-24 tw-flex tw-flex-col tw-gap-8">
                            <Button
                                variant="primary"
                                text="CHOOSE ANOTHER PASSWORD"
                                onClick={onResetPasswordForm}
                                disabled={loading}
                            />
                            <Button
                                variant="secondary"
                                text="USE ANYWAY"
                                onClick={onResetPassword}
                                disabled={loading}
                            />
                        </div>
                    </>
                ) : (
                    <div>
                        <p className="tw-mb-16">
                            Set a password for your account, which must contain at least 12
                            characters.
                        </p>
                        <InputPassword
                            autoComplete="new-password"
                            autoFocus={true}
                            className={inputBorderClass}
                            forgotPassword={false}
                            label="Password"
                            name="newPassword"
                            onChange={onNewPasswordChange}
                            password={newPassword}
                        />
                        <InputPassword
                            autoComplete="new-password"
                            className={inputBorderClass}
                            forgotPassword={false}
                            label="Confirm password"
                            name="confirmPassword"
                            onChange={onConfirmPasswordChange}
                            password={confirmPassword}
                        />
                        {loading ? (
                            <div style={{ paddingTop: 20 }}>
                                <Loading />
                            </div>
                        ) : (
                            <SubmitButton
                                variant="primary"
                                value={isFirstTimeUser ? 'Set Password' : 'Reset Password'}
                                onClick={onResetPassword}
                                disabled={loading}
                            />
                        )}
                        {errorMessage && !loading ? (
                            <ErrorMessageBox errorMessage={errorMessage} />
                        ) : null}
                    </div>
                )}
            </form>
        </div>
    );
};

export default ResetPassword;
