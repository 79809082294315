import React from 'react';
import { ErrorMessageBox } from '../common/ErrorMessageBox';
import { SubmitButton } from '../common/SubmitButton';

type ResendPasswordLinkProps = {
    loading: boolean;
    onForgotPassword: React.FormEventHandler<HTMLFormElement>;
};

const ResendPasswordLink = ({ loading, onForgotPassword }: ResendPasswordLinkProps) => (
    <form
        onSubmit={(event) => !loading && onForgotPassword(event)}
        data-testid="resend-password-link-form"
    >
        <ErrorMessageBox
            errorMessage="Your link has expired. Due to security restrictions, links are only valid for 24 hours
            after being issued. Don’t worry, you can get a new valid link sent to you by e-mail by
            clicking the button below"
            textSize="large"
        />
        <div className="tw-mt-24">
            <SubmitButton disabled={loading} value="Send new valid link" />
        </div>
    </form>
);

export default ResendPasswordLink;
