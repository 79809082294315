import React from 'react';

import { blobUrl } from 'constants/Common';

type FailedSsoContentProps = {
    systemName: string;
    ssoAuthenticateError?: string;
    emailAddress?: string;
};

export const FailedSsoContent = (props: FailedSsoContentProps) => {
    const { systemName, ssoAuthenticateError, emailAddress } = props;

    let showSupportMessage = true;
    const supportMessage = 'Please contact SalesScreen support if the issue persists';

    let message = '';

    switch (ssoAuthenticateError) {
        case 'ServerError':
        default:
            message = `We're sorry, but something went wrong on our end. Our team has been notified and we're working to fix it as soon as possible.`;
            break;
        case 'InvalidClaims':
            message = `One or more required user claims expected to be returned by the identity provider, ${systemName}, were either missing or had invalid values.`;
            break;
        case 'InvalidToken':
            message = `The access token provided by ${systemName} was invalid.`;
            break;
        case 'InvalidIssuer':
            message = `The issuer value returned by the identity provider, ${systemName}, has not been approved by SalesScreen.`;
            break;
        case 'EmailNotVerified':
            message = `The email ${emailAddress} has not been verified by the identity provider, ${systemName}.`;
            showSupportMessage = false;
            break;
        case 'CompanyDisabled':
            message = `The SalesScreen organization you're trying to sign in to has likely been disabled.`;
            break;
        case 'UserDisabled':
            message = `Your SalesScreen user account has likely been disabled.`;
            break;
        case 'UserNoSSoEntry':
            message = `You are not allowed to sign in to SalesScreen using ${systemName} as the identity provider, without first having SalesScreen configure your single sign-on settings.`;
            break;
        case 'UserNoEmailMatch':
            message = `We could not find a SalesScreen account associated with your email ${emailAddress}`;
            showSupportMessage = false;
            break;
        case 'UserAccountActivationEmailMismatch':
            message = `The email ${emailAddress} is different than the email that the SalesScreen invitation was sent to.`;
            showSupportMessage = false;
            break;
        case 'UserAlreadyRegistered':
            message = `We can't provision a new SalesScreen user account since the account already exists.`;
            break;
        case 'SsoProviderNotEnabled':
            message = `Your organization has not enabled ${systemName} as a single sign-on provider.`;
            showSupportMessage = false;
            break;
    }

    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                <img
                    style={{ display: 'inline-block', width: '150px' }}
                    src={`${blobUrl}dashboard/error/no-access.png`}
                    alt="Failed SSO Login"
                />
                <p className="tw-text-xl tw-m-0">Unable to sign in with</p>
                <p className="tw-text-xl tw-font-bold tw-m-0">{systemName}</p>
            </div>
            <div
                className="tw-border-t tw-border-b dark:tw-border-neutral-dark"
                style={{
                    padding: '20px',
                    margin: '20px 0px',
                }}
            >
                <p>{message}</p>
                {showSupportMessage && <p>{supportMessage}</p>}
            </div>
        </div>
    );
};
