import React, { Component } from 'react';

import { LoginContext } from '../contexts/LoginContext';
import ForgotPasswordView from '../components/forgotPassword/ForgotPasswordView';

class ForgotPasswordContainer extends Component {
    render() {
        const {
            email,
            errorMessage,
            loading,
            onEmailChange,
            onForgotPassword,
            resetForgotPasswordEmailSent,
            resetPasswordEmailSent,
            clearErrorMessage,
        } = this.context;
        return (
            <ForgotPasswordView
                clearErrorMessage={clearErrorMessage}
                email={email}
                emailSent={resetPasswordEmailSent}
                errorMessage={errorMessage}
                loading={loading}
                onEmailChange={onEmailChange}
                onForgotPassword={onForgotPassword}
                resetForgotPasswordEmailSent={resetForgotPasswordEmailSent}
            />
        );
    }
}

ForgotPasswordContainer.contextType = LoginContext;

export default ForgotPasswordContainer;
