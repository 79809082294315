import React, { useEffect, useRef, useState } from 'react';

import { blobUrl } from 'constants/Common';

import { get, makeUrl } from 'apiutils';
import { Carousel, SlideHandle } from 'nuka-carousel';
import { useInView } from 'react-intersection-observer';

import { Button } from 'components/buttons/Button';

import { LoginPageContentElement } from './LoginPageContentElement';

type ContentResponse = {
    content: SanityContentElement[];
};

export type SanityContentElement = {
    category: ContentCategory;
    image: string;
    link: string;
    linkText: string;
    paragraph: string;
    title: string;
    videoUrl: string;
};

export enum ContentCategory {
    BLOG = 'blog',
    UPDATES = 'updates',
    WEBINARS = 'webinars',
    PRODUCT = 'product',
}

export const LoginPageContent = () => {
    const [singleContentElement, setSingleContentElement] = useState(false);
    const [loginPageContent, setContent] = useState<ContentResponse>(null);
    const [imageLoadCount, setImageLoadCount] = useState<number>(null);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [ref, inView] = useInView({
        threshold: 1,
    });

    const carouselRef = useRef<SlideHandle>(null);

    const getContent = () => {
        get(makeUrl('CmsContent', 'GetLoginContent'))
            .then((res) => {
                const response = res.body as ContentResponse;
                setContent(response);
                if (response.content.length === 1) {
                    setSingleContentElement(true);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        if (
            loginPageContent &&
            imageLoadCount === loginPageContent.content.filter((x) => x.image).length
        ) {
            setImagesLoaded(true);
        }
    }, [loginPageContent, imageLoadCount]);

    useEffect(() => {
        if (inView && !loginPageContent) {
            getContent();
        }
    }, [inView, loginPageContent]);

    if (!loginPageContent) {
        return (
            <img
                ref={ref}
                src={`${blobUrl}common/logos/inverted-horizontal.png`}
                alt="SalesScreen Logo"
                className="tw-w-[200px]"
            />
        );
    }

    return (
        <div className="2xl:tw-px-48 tw-px-16 tw-w-full tw-group" ref={ref}>
            {loginPageContent.content?.map((sanityContentElement, index) => {
                const { image, title } = sanityContentElement;
                return (
                    <img
                        key={index}
                        src={image ? image : `${blobUrl}common/logos/inverted-horizontal.png`}
                        alt={title}
                        onLoad={() => setImageLoadCount(imageLoadCount + 1)}
                        className="tw-hidden"
                    />
                );
            })}
            {imagesLoaded && (
                <div>
                    <Carousel
                        ref={carouselRef}
                        autoplay={true}
                        autoplayInterval={7000}
                        scrollDistance="screen"
                        wrapMode="wrap"
                    >
                        {loginPageContent.content?.map((sanityContentElement, index) => {
                            return (
                                <LoginPageContentElement
                                    key={index}
                                    content={sanityContentElement}
                                />
                            );
                        })}
                    </Carousel>
                    {!singleContentElement && (
                        <>
                            <div className="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-flex tw-items-center tw-justify-between tw-z-10 tw-px-24">
                                <Button
                                    icon="arrow-left"
                                    variant="secondary"
                                    onClick={() => carouselRef.current.goBack()}
                                    wrapperClasses="tw-ml-8"
                                />
                            </div>
                            <div className="tw-absolute tw-right-0 tw-top-0 tw-h-full tw-flex tw-items-center tw-justify-between tw-z-10 tw-px-24">
                                <Button
                                    icon="arrow-right"
                                    variant="secondary"
                                    onClick={() => carouselRef.current.goForward()}
                                    wrapperClasses="tw-mr-8"
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
