import React, { Component } from 'react';

import LoginView from '../components/login/LoginView';
import { LoginContext } from '../contexts/LoginContext';

class LoginContainer extends Component {
    render() {
        const {
            browser,
            email,
            errorMessage,
            getLoginProviders,
            loading,
            loginFailed,
            loginPassword,
            loginProvidersLoaded,
            loginShowMicrosoftLink,
            loginShowPassword,
            onEmailChange,
            onPasswordChange,
            onSignIn,
            onSwitchAccount,
            ssoProviders,
            publicSsoProviders,
        } = this.context;
        return (
            <LoginView
                browser={browser}
                email={email}
                errorMessage={errorMessage}
                getLoginProviders={getLoginProviders}
                loading={loading}
                loginFailed={loginFailed}
                loginProvidersLoaded={loginProvidersLoaded}
                onEmailChange={onEmailChange}
                onPasswordChange={onPasswordChange}
                onSignIn={onSignIn}
                onSwitchAccount={onSwitchAccount}
                password={loginPassword}
                showMicrosoftLink={loginShowMicrosoftLink}
                showPassword={loginShowPassword}
                ssoProviders={ssoProviders}
                publicSsoProviders={publicSsoProviders}
            />
        );
    }
}

LoginContainer.contextType = LoginContext;

export default LoginContainer;
