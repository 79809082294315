import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Icon } from 'components/icons/Icon';

type BackToLoginLinkProps = {
    onClick?: () => void;
};

export const BackToLoginLink = ({ onClick = () => {} }: BackToLoginLinkProps) => {
    const navigate = useNavigate();
    return (
        <a
            className="tw-inline-flex tw-items-center tw-text-primary dark:tw-text-primary-dark hover:tw-text-primary-hover dark:hover:tw-text-primary-dark-hover tw-gap-4 tw-mt-24 tw-group"
            onClick={() => {
                onClick();
                navigate('/');
            }}
        >
            <Icon icon="arrow2-left" size="x-small" />
            <span className="group-hover:tw-underline">Back to login</span>
        </a>
    );
};
