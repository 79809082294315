import React from 'react';

import { blobUrl } from 'constants/Common';

export const FailedNINAA = () => {
    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                <img
                    style={{ display: 'inline-block', width: '150px' }}
                    src={`${blobUrl}dashboard/error/no-access.png`}
                    alt="Failed SSO Login"
                />
                <p className="tw-text-xl tw-m-0">Unable to sign in with</p>
                <p className="tw-text-xl tw-font-bold tw-m-0">Nordea NINAA</p>
            </div>
            <div
                className="tw-border-t tw-border-b dark:tw-border-neutral-dark"
                style={{
                    padding: '20px',
                    margin: '20px 0px',
                }}
            >
                <p style={{ marginTop: '0px' }}>
                    You have not been granted the necessary access rights within Nordea to access
                    SalesScreen. Contact your closest manager in Nordea for questions regarding
                    SalesScreen access.
                </p>
            </div>
        </div>
    );
};
