import React from 'react';

import TwoFactorProvider from './TwoFactorProvider';
import { blobUrl } from 'constants/Common';
import { Button } from 'components/buttons/Button';
import { ErrorMessageBox } from '../common/ErrorMessageBox';
import { TextContentBox } from '../common/TextContentBox';
import { Loading } from 'components/loadings/Loading';

const SendCodeView = ({
    errorMessage,
    loading,
    onTwoFactorSelectProvider,
    onTwoFactorSendCode,
    twoFactorActiveProvider,
    twoFactorProviders,
    twoFactorSendCodeError,
}) => {
    const getProviderDesc = () => {
        switch (twoFactorActiveProvider.toLowerCase()) {
            case 'email':
                return 'A code will be sent to your registered email address.';
            case 'phone':
                return 'A code will be sent to your registered phone number.';
            default:
                return '';
        }
    };

    const smsDisabled = twoFactorProviders.indexOf('Phone') === -1;

    return (
        <div className="loginActionsColumn">
            <div className="loginActions twoFactorAuthentication">
                <TextContentBox
                    title="Two-factor Authentication"
                    paragraph1={
                        !loading && !twoFactorSendCodeError
                            ? 'Please choose a provider below to complete the two-factor authentication.'
                            : null
                    }
                />
                {!loading && !twoFactorSendCodeError && (
                    <div className="twoFactorContentContainer tw-mt-24">
                        <div className="tw-grid tw-grid-cols-2 tw-gap-8">
                            <TwoFactorProvider
                                active={twoFactorActiveProvider === 'Email'}
                                disabled={false}
                                name="Email"
                                imageUrl={`${blobUrl}dashboard/illustrations/small/2FA-email.png`}
                                onSelectProvider={onTwoFactorSelectProvider}
                            />
                            <TwoFactorProvider
                                active={twoFactorActiveProvider === 'Phone'}
                                disabled={smsDisabled}
                                name="Phone"
                                imageUrl={`${blobUrl}dashboard/illustrations/small/2FA-sms.png`}
                                onSelectProvider={onTwoFactorSelectProvider}
                            />
                        </div>

                        {!!twoFactorActiveProvider && (
                            <p className="tw-mt-12">{getProviderDesc()}</p>
                        )}

                        {!!twoFactorActiveProvider && (
                            <Button text="Send Code" onClick={onTwoFactorSendCode} />
                        )}
                        {errorMessage && (
                            <div className="tw-mb-12">
                                <ErrorMessageBox errorMessage={errorMessage} />
                            </div>
                        )}
                    </div>
                )}

                {loading && (
                    <div className="twoFactorLoadingContainer">
                        <div className="loading-wrapper tw-absolute -tw-bottom-[100px]">
                            <Loading />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SendCodeView;
