import 'vite/modulepreload-polyfill';
import 'styles/tailwind.css';

import React from 'react';

import { createRoot } from 'react-dom/client';

import { initRaygun } from 'helpers/tracking/ErrorTracking';

import App from './appWrapper';

initRaygun(['Login']);

createRoot(document.getElementById('loginComponentWrapper')).render(<App />);
