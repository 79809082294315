import React from 'react';

import { useParams, useLocation } from 'react-router-dom';

import { BackToLoginLink } from '../components/common/BackToLoginLink';
import { FailedNINAA } from '../components/sso/failed/FailedNINAA';
import { FailedSsoContent } from '../components/sso/failed/FailedSsoContent';

export const FailedSSOContainer = () => {
    const { provider } = useParams<{ provider: string }>();

    // Get search query params
    const urlSearchParams = new URLSearchParams(useLocation().search);
    const ssoAuthenticateError = urlSearchParams.get('error');
    const emailAddress = urlSearchParams.get('email');

    let errorContent = null;
    switch (provider) {
        case 'NIINA':
            errorContent = <FailedNINAA />;
            break;
        default:
            errorContent = (
                <FailedSsoContent
                    systemName={provider ?? 'SSO provider'}
                    ssoAuthenticateError={ssoAuthenticateError}
                    emailAddress={emailAddress}
                />
            );
            break;
    }

    return (
        <div className="ssoFailed">
            {errorContent}
            <div className="tw-px-24">
                <BackToLoginLink />
            </div>
        </div>
    );
};
