import React from 'react';

export const SignedInView = () => {
    return (
        <div className="tw-h-full tw-flex tw-flex-col tw-gap-12 tw-items-center">
            <p className="tw-text-xl tw-font-bold">You are now signed in</p>
            <a
                href="https://app.salesscreen.com"
                className="tw-p-12 tw-rounded tw-border dark:tw-border-neutral-dark"
            >
                Continue to SalesScreen
            </a>
        </div>
    );
};
