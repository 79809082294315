import React from 'react';

import { Icon } from 'components/icons/Icon';

import InputPassword from '../common/InputPassword';
import InputText from '../common/InputText';
import { SubmitButton } from '../common/SubmitButton';

const LoginProvidersForm = ({
    browser,
    email,
    getLoginProviders,
    loading,
    loginFailed,
    onEmailChange,
}) => {
    const inputBorderClass = loginFailed ? 'warning' : '';
    return (
        <form className="tw-mt-16">
            <InputText
                autoComplete="username"
                autoFocus={true}
                browser={browser}
                className={inputBorderClass}
                onChange={onEmailChange}
                label="Enter your email"
                name="username"
                placeholder="example@salesscreen.com"
                type="email"
                value={email}
            />
            <InputPassword autoComplete="current-password" hidden={true} name="password" />
            <SubmitButton disabled={loading} onClick={getLoginProviders} value="Next" />
            {browser === 'ie' && !loading && (
                <div className="tw-px-16 tw-py-12 tw-rounded tw-shadow-xs tw-bg-warning-subdued tw-mt-16 tw-text-warning">
                    <div className="tw-flex tw-items-center tw-font-medium tw-pb-4 tw-mb-4 tw-border-b tw-border-warning">
                        <Icon icon="warning" />
                        You are using an outdated web browser.
                    </div>
                    <p>
                        SalesScreen will soon end support for Internet Explorer. To keep using
                        SalesScreen you will need to switch to a modern browser, eg. Google Chrome,
                        Safari, Mozilla Firefox or Microsoft Edge
                    </p>
                </div>
            )}
        </form>
    );
};

export default LoginProvidersForm;
