import React from 'react';

type SubmitButtonProps = {
    value: string;
    variant?: 'primary';
    disabled?: boolean;
    onClick?: any;
    testId?: string;
};

export const SubmitButton = ({
    testId,
    value,
    variant = 'primary',
    disabled = false,
    onClick,
}: SubmitButtonProps) => {
    const getButtonBackgroundClasses = () => {
        if (disabled) {
            return 'tw-bg-neutral-disabled dark:tw-bg-neutral-dark-disabled';
        }

        return 'tw-bg-primary hover:tw-bg-primary-hover active:tw-bg-primary-active dark:tw-bg-primary-dark dark:hover:tw-bg-primary-dark-hover dark:active:tw-bg-primary-dark-active';
    };

    const getButtonTextClasses = () => {
        if (disabled) {
            return 'tw-text-neutral-disabled dark:tw-text-neutral-dark-disabled';
        }

        return 'tw-text-neutral-inverse dark:tw-text-neutral-dark-inverse';
    };

    const getButtonShadowClasses = () => {
        if (disabled) {
            return '';
        }

        return 'focus:tw-shadow-xs-focus tw-shadow-xs-primary focus-visible:tw-shadow-xs-primary-focus active:tw-shadow-none';
    };

    return (
        <input
            data-testid={testId}
            className={`${getButtonBackgroundClasses()} ${getButtonTextClasses()} ${getButtonShadowClasses()} tw-transition-all tw-no-underline tw-rounded tw-inline-block tw-w-full tw-px-16 tw-h-[38px] tw-font-medium hover:tw-cursor-pointer tw-uppercase tw-text-size-3 tw-text-m tw-appearance-none`}
            onClick={onClick}
            type="submit"
            value={value}
            disabled={disabled}
        />
    );
};
