import React, { Component } from 'react';

import ResetPasswordView from '../components/resetPassword/ResetPasswordView';
import { LoginContext } from '../contexts/LoginContext';

const getQueryParams = () => {
    const paramsString = window.location.href.split('?code=')[1];
    const paramsArray = paramsString ? paramsString.split('&email=') : null;
    const code = paramsArray ? paramsArray[0] : null;
    const email = paramsArray ? paramsArray[1] : null;

    return { code, email };
};

class ResetPasswordContainer extends Component {
    static contextType = LoginContext;
    declare context: React.ContextType<typeof LoginContext>;

    componentDidMount() {
        // If email and resetPasswordCode exists in the context state, the email + code has already been validated
        const { email, resetPasswordCode, validateResetPasswordToken } = this.context;

        // If not, retrieve the values from the URL and validate them
        if (!email || !resetPasswordCode) {
            const queryParams = getQueryParams();
            validateResetPasswordToken(queryParams.email, queryParams.code);
        }
    }

    render() {
        return <ResetPasswordView {...this.context} />;
    }
}

export default ResetPasswordContainer;
