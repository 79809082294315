import { blobUrl } from 'constants/Common';
import React, { useEffect } from 'react';

export const Redirect = ({ redirectUrl }) => {
    useEffect(() => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    }, [redirectUrl]);
    return (
        <div className="tw-h-screen tw-w-full tw-flex tw-items-center tw-justify-center tw-bg-neutral-body">
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-64">
                <img
                    src={`${blobUrl}common/logos/standard-horizontal.png`}
                    alt="SalesScreen Logo"
                    className="lg:tw-w-[150px] tw-w-[120px]"
                />
                <div className="tw-bg-neutral tw-shadow-xs tw-p-24 tw-rounded">
                    <h1 className="tw-font-bold tw-text-xl tw-m-0">
                        You are now being returned to the application
                    </h1>
                    <p className="tw-text-m tw-m-0 tw-mt-4">
                        Once complete, you may close this tab.
                    </p>
                </div>
            </div>
        </div>
    );
};
