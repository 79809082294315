import React from 'react';

import { useLocation } from 'react-router-dom';

import { SsoProviderModel } from 'types/sso/sso';

import { Loading } from 'components/loadings/Loading';

import { BackToLoginLink } from '../common/BackToLoginLink';
import { TextContentBox } from '../common/TextContentBox';
import SsoProviders from '../login/SsoProviders';

import NewPasswordLinkSent from './NewPasswordLinkSent';
import ResendPasswordLink from './ResendPasswordLink';
import ResetPassword from './ResetPasswordForm';

type ResetPasswordViewProps = {
    confirmPassword: string;
    email: string;
    errorMessage: string;
    loading: boolean;
    newPassword: string;
    onConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement>;
    onContinueToSalesScreen: React.FormEventHandler<HTMLInputElement>;
    onForgotPassword: React.FormEventHandler<HTMLFormElement>;
    onNewPasswordChange: React.ChangeEventHandler<HTMLInputElement>;
    onResetPassword: React.FormEventHandler<HTMLInputElement>;
    onResetPasswordForm: React.FormEventHandler<HTMLInputElement>;
    requireTwoFactor: boolean;
    resetForgotPasswordEmailSent: () => void;
    resetPasswordBreachWarning: boolean;
    resetPasswordEmailSent: boolean;
    resetPasswordFailed: boolean;
    resetPasswordInvalidLink: boolean;
    resetPasswordSuccess: boolean;
    resetPasswordCode: string;
    forceSsoLogin: boolean;
    ssoProviders: SsoProviderModel[];
};

const ResetPasswordView = ({
    confirmPassword,
    email,
    errorMessage,
    loading,
    newPassword,
    onConfirmPasswordChange,
    onContinueToSalesScreen,
    onForgotPassword,
    onNewPasswordChange,
    onResetPassword,
    onResetPasswordForm,
    requireTwoFactor,
    resetForgotPasswordEmailSent,
    resetPasswordBreachWarning,
    resetPasswordCode,
    resetPasswordEmailSent,
    resetPasswordFailed,
    resetPasswordInvalidLink,
    resetPasswordSuccess,
    forceSsoLogin,
    ssoProviders,
}: ResetPasswordViewProps) => {
    const location = useLocation();
    const isFirstTimeUser = location.pathname === '/set-password';

    const headline =
        ssoProviders.length > 0
            ? 'Access with your identity provider'
            : isFirstTimeUser
              ? 'Set your password'
              : 'Reset your password';

    if (loading && !resetPasswordCode) {
        return (
            <div className="loginActionsColumn">
                <div className="loginActions resetPassword">
                    <div style={{ height: '50%' }}>
                        <Loading />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="loginActionsColumn">
            <div className="loginActions resetPassword">
                <div className="tw-mb-16">
                    <TextContentBox title={headline} />
                </div>
                {resetPasswordInvalidLink &&
                    (resetPasswordEmailSent ? (
                        <NewPasswordLinkSent email={email} />
                    ) : (
                        <ResendPasswordLink onForgotPassword={onForgotPassword} loading={loading} />
                    ))}

                {ssoProviders?.length > 0 && (
                    <>
                        <p className="tw-mb-16">Sign in with one of these identity providers.</p>
                        <SsoProviders
                            ssoProviders={ssoProviders}
                            displayAlternativeInput={!forceSsoLogin}
                            invitationEmailAddress={email}
                        />
                    </>
                )}

                {!resetPasswordInvalidLink && !forceSsoLogin && (
                    <ResetPassword
                        confirmPassword={confirmPassword}
                        errorMessage={errorMessage}
                        isFirstTimeUser={isFirstTimeUser}
                        loading={loading}
                        newPassword={newPassword}
                        onConfirmPasswordChange={onConfirmPasswordChange}
                        onContinueToSalesScreen={onContinueToSalesScreen}
                        onNewPasswordChange={onNewPasswordChange}
                        onResetPassword={onResetPassword}
                        onResetPasswordForm={onResetPasswordForm}
                        requireTwoFactor={requireTwoFactor}
                        resetPasswordBreachWarning={resetPasswordBreachWarning}
                        resetPasswordFailed={resetPasswordFailed}
                        resetPasswordSuccess={resetPasswordSuccess}
                    />
                )}
                {!resetPasswordSuccess && (
                    <BackToLoginLink
                        onClick={() => resetPasswordEmailSent && resetForgotPasswordEmailSent()}
                    />
                )}
            </div>
        </div>
    );
};

export default ResetPasswordView;
