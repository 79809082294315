import React from 'react';

type ErrorMessageBoxProps = {
    variant?: 'error' | 'warning';
    errorMessage: string;
    textSize?: 'normal' | 'large';
};

export const ErrorMessageBox = (props: ErrorMessageBoxProps) => {
    const { errorMessage, textSize, variant = 'error' } = props;
    return (
        <div
            className={`${textSize === 'large' ? 'tw-text-m' : 'tw-text-s'} ${
                variant === 'error'
                    ? 'tw-bg-danger-subdued tw-text-danger dark:tw-bg-danger-dark-subdued dark:tw-text-danger-dark'
                    : 'tw-bg-warning-subdued tw-text-warning dark:tw-bg-warning-dark-subdued dark:tw-text-warning-dark'
            } tw-flex tw-gap-8  tw-px-12 tw-py-8 tw-rounded tw-border dark:tw-border-neutral-dark tw-mt-12 tw-relative tw-items-stretch`}
        >
            {errorMessage}
        </div>
    );
};
