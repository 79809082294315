import React, { useEffect } from 'react';

import InputText from '../common/InputText';
import { BackToLoginLink } from '../common/BackToLoginLink';
import { SubmitButton } from '../common/SubmitButton';
import { ErrorMessageBox } from '../common/ErrorMessageBox';
import { TextContentBox } from '../common/TextContentBox';
import { Loading } from 'components/loadings/Loading';

type ForgotPasswordViewProps = {
    clearErrorMessage: () => void;
    email: string;
    emailSent: boolean;
    errorMessage?: string;
    loading: boolean;
    onEmailChange: React.ChangeEventHandler<HTMLInputElement>;
    onForgotPassword: React.FormEventHandler<HTMLFormElement>;
    resetForgotPasswordEmailSent: () => void;
};

const ForgotPasswordView = ({
    clearErrorMessage,
    email,
    emailSent,
    errorMessage,
    loading,
    onEmailChange,
    onForgotPassword,
    resetForgotPasswordEmailSent,
}: ForgotPasswordViewProps) => {
    useEffect(() => {
        clearErrorMessage();
    }, []);
    return (
        <div className="loginActionsColumn">
            <div className="loginActions forgotPassword">
                <TextContentBox
                    title={!emailSent ? 'Forgot your password?' : 'Email sent'}
                    paragraph1={
                        !emailSent
                            ? "Don't worry! Enter your email in the field below, and we'll send you instructions on how to get back into SalesScreen."
                            : null
                    }
                />
                {emailSent ? (
                    <div>
                        <p className="tw-text-m tw-m-0 tw-mt-4">
                            An email was sent to <span className="bold">{email}</span> with
                            instructions on how to reset your password. If you do not receive this
                            within 3 minutes, feel free to reach out to us on the support chat in
                            the bottom right corner.
                        </p>
                        {errorMessage && (
                            <div className="tw-mb-12">
                                <ErrorMessageBox errorMessage={errorMessage} />
                            </div>
                        )}
                    </div>
                ) : (
                    <form
                        onSubmit={(event) => !loading && onForgotPassword(event)}
                        data-testid="reset-password-form"
                        className="tw-mt-[38px]"
                    >
                        <div>
                            <InputText
                                autoComplete="username"
                                label="Your email"
                                onChange={onEmailChange}
                                name="username"
                                placeholder="example@domain.com"
                                type="email"
                                value={email}
                            />
                            <SubmitButton
                                data-testid="reset-password-button"
                                disabled={loading || !email}
                                value="Reset Your Password"
                            />
                            {errorMessage && (
                                <div className="tw-mb-12">
                                    <ErrorMessageBox errorMessage={errorMessage} />
                                </div>
                            )}
                        </div>
                    </form>
                )}

                <BackToLoginLink onClick={() => emailSent && resetForgotPasswordEmailSent()} />

                {loading && (
                    <div className="loading-wrapper -tw-bottom-[100px]">
                        <Loading />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ForgotPasswordView;
