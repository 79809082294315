import React from 'react';

const TwoFactorProvider = ({ active, disabled, name, imageUrl, onSelectProvider }) => {
    const onClick = () => {
        if (disabled) return;

        onSelectProvider(name);
    };

    return (
        <div
            className={`tw-relative ${
                !disabled ? 'hover:tw-cursor-pointer' : 'hover:tw-cursor-not-allowed'
            }`}
        >
            <div
                className={`${active ? 'tw-border-primary dark:tw-border-primary-dark' : 'tw-border-neutral dark:tw-border-neutral-dark'} ${
                    disabled ? 'tw-opacity-50' : ''
                } tw-gap-12 tw-flex tw-items-center tw-px-12 tw-py-8 tw-rounded tw-border-2 hover:tw-shadow-xs tw-transition-shadow`}
                onClick={onClick}
            >
                <div className="tw-rounded">
                    <img className="tw-w-[50px]" src={imageUrl} />
                </div>
                <div className="tw-font-medium">{name}</div>
            </div>
        </div>
    );
};

export default TwoFactorProvider;
