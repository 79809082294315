import { Button } from 'components/buttons/Button';
import React from 'react';
import { TextContentBox } from '../common/TextContentBox';

const PhoneNumberSuccessView = () => {
    const onContinueClick = () => {
        window.location.href = '/';
    };
    return (
        <div className="loginActionsColumn">
            <div className="loginActions twoFactorAuthentication">
                <div className="twoFactorContentContainer">
                    <TextContentBox
                        title="Two-factor Authentication"
                        paragraph1="Your account is now configured with two-factor authentication. When signing in, you will now be asked to provide a verification code, sent by SMS to the number you just registered."
                    />
                    <Button
                        wrapperClasses="tw-mt-24"
                        text="Continue to SalesScreen"
                        variant="secondary"
                        onClick={onContinueClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default PhoneNumberSuccessView;
