import React, { useCallback } from 'react';

import { SsoProviderModel } from 'types/sso/sso';

type SsoProvidersProps = {
    ssoProviders: SsoProviderModel[];
    displayAlternativeInput?: boolean;
    displayAlternativeInputAbove?: boolean;
    invitationEmailAddress?: string;
};

const SsoProviders = (props: SsoProvidersProps) => {
    const {
        ssoProviders,
        displayAlternativeInput,
        displayAlternativeInputAbove,
        invitationEmailAddress,
    } = props;

    //#region Helper functions

    const getLoginLink = (provider: SsoProviderModel) => {
        return `${provider.callbackPath}?returnUrl=${getEncodedReturnUrl()}${provider.issuer ? `&iss=${provider.issuer}` : ''}${invitationEmailAddress ? `&invitationEmailAddress=${invitationEmailAddress}` : ''}`;
    };

    const getEncodedReturnUrl = () => {
        //@ts-ignore RETURN_URL is fetched from the window object
        return encodeURIComponent(window.RETURN_URL as string);
    };

    const getSsoProviderElements = useCallback(() => {
        return ssoProviders.map((provider) => {
            return (
                <div
                    key={provider.name}
                    className="tw-flex tw-justify-center tw-h-[40px] tw-group tw-border tw-border-neutral dark:tw-border-neutral-dark tw-rounded"
                >
                    <div
                        className="tw-flex tw-flex-row tw-items-center tw-cursor-pointer tw-w-full tw-rounded tw-transition-colors tw-text-neutral-inverse"
                        onClick={() => {
                            window.location.href = getLoginLink(provider);
                        }}
                    >
                        <div className="tw-bg-primary-selected tw-h-full tw-p-8 tw-rounded-l">
                            <img
                                src={provider.logoUrl}
                                className="tw-h-full group-hover:tw-scale-90 tw-transition-transform"
                            />
                        </div>
                        <div className="tw-font-medium tw-text-m tw-tw-truncate tw-pl-12 tw-bg-neutral-inverse group-hover:tw-bg-neutral-inverse/90 tw-h-full tw-flex-1 tw-flex tw-items-center tw-rounded-r">
                            Sign in with {provider.name}
                        </div>
                    </div>
                </div>
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ssoProviders]);

    //#endregion

    //#region Layout

    const providerListComponent = (
        <div className="tw-flex tw-flex-col tw-gap-8 tw-max-w-[240px] tw-mx-auto">
            {getSsoProviderElements()}
        </div>
    );

    const orComponent = displayAlternativeInput && (
        <div className="tw-flex tw-flex-row tw-w-full tw-my-12 tw-justify-between tw-items-center">
            <div className="tw-w-[47%] tw-border-t tw-border-neutral dark:tw-border-neutral-dark"></div>
            <div className="tw-w-[6%] tw-text-center">or</div>
            <div className="tw-w-[47%] tw-border-t tw-border-neutral dark:tw-border-neutral-dark"></div>
        </div>
    );

    //#endregion

    return displayAlternativeInputAbove ? (
        <>
            {orComponent}
            {providerListComponent}
        </>
    ) : (
        <>
            {providerListComponent}
            {orComponent}
        </>
    );
};

export default SsoProviders;
