import 'styles/login/login.less';

import React, { useEffect } from 'react';

import { blobUrl } from 'constants/Common';

import { useTheme } from 'components/theme/ThemeProvider';

import { isIE11Browser } from 'helpers/BrowserFunctions';

import { useUrlQueryParams } from 'hooks/useUrlQueryParams';

import { LoginPageContent } from './LoginPageContent';
import { Redirect } from './redirect/Redirect';

const disclaimer = (
    <div className="tw-text-center tw-text-s">
        <div className="tw-flex tw-justify-center tw-items-center tw-gap-8 tw-text-neutral-subdued dark:tw-text-neutral-dark-subdued">
            <span>All rights reserved @ SalesScreen</span>
            <img
                src={`${blobUrl}common/logos/mark-lightmode.svg`}
                alt="SalesScreen Icon"
                className="tw-h-16"
            />
            <span>{new Date().getFullYear()}</span>
        </div>
    </div>
);

export const LoginApp = ({ children }) => {
    const resizeOps = () => {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };
    useEffect(() => {
        window.addEventListener('resize', resizeOps);
        return () => {
            window.removeEventListener('resize', resizeOps);
        };
    }, []);

    const { theme } = useTheme();

    const urlQueryParams = useUrlQueryParams();
    const redirectUrl = urlQueryParams.get('r');

    if (redirectUrl) {
        return <Redirect redirectUrl={redirectUrl} />;
    }

    return (
        <div
            className=" tw-flex tw-flex-col"
            style={{
                height: 'calc(var(--vh, 1vh) * 100)',
            }}
        >
            <div className="tw-h-full tw-flex tw-justify-center tw-bg-neutral-body dark:tw-bg-neutral-dark-body tw-bg-cover tw-w-full">
                <div className="tw-max-w-[2400px] tw-relative tw-w-full">
                    <div className="tw-grid 2xl:tw-grid-cols-[800px_minmax(0,_1fr)] xl:tw-grid-cols-[600px_minmax(0,_1fr)] lg:tw-grid-cols-[500px_minmax(0,_1fr)]  tw-grid-cols-1 tw-w-full tw-h-full tw-border-l tw-border-r dark:tw-border-neutral-dark tw-justify-center tw-p-24 lg:tw-pt-24 tw-pt-[100px]">
                        <img
                            src={`${blobUrl}common/logos/${theme === 'light' ? 'standard-horizontal' : 'inverted-horizontal'}.png`}
                            alt="SalesScreen Logo"
                            className="lg:tw-w-[150px] tw-w-[120px] tw-absolute tw-left-24 tw-top-24"
                        />
                        <div className="tw-relative tw-flex tw-items-center tw-justify-center lg:tw-rounded-t-none tw-rounded-t lg:tw-py-0 md:tw-py-48 lg:tw-px-0 md:tw-px-24">
                            <div className="loginFrame tw-relative">
                                <div className="lg:tw-px-[80px] loginWrapper lg:tw-rounded-r tw-rounded-b">
                                    <div className="lg:tw-w-[440px] tw-w-full tw-max-w-[440px]">
                                        {isIE11Browser() ? (
                                            <div>
                                                <h2 className="tw-text-l">
                                                    SalesScreen does no longer work with Internet
                                                    Explorer.
                                                </h2>
                                                <p>
                                                    To use SalesScreen you will need to switch to a
                                                    modern browser, eg. Google Chrome, Safari,
                                                    Mozilla Firefox or Microsoft Edge
                                                </p>
                                            </div>
                                        ) : (
                                            children
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="tw-hidden lg:tw-inline-block tw-absolute tw-bottom-12 tw-left-0 tw-w-full">
                                {disclaimer}
                            </div>
                        </div>
                        <div className="tw-w-full lg:tw-pb-0 tw-pb-24 md:tw-inline-block tw-hidden">
                            <div
                                className="tw-relative tw-w-full tw-h-full tw-rounded tw-bg-cover tw-bg-no-repeat tw-flex tw-items-center tw-justify-center tw-bg-[#0d2228]"
                                style={{
                                    backgroundImage: `url(${blobUrl}login/login-background.svg)`,
                                }}
                            >
                                <LoginPageContent />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tw-inline-block lg:tw-hidden tw-w-full tw-pt-12 tw-pb-16">
                {disclaimer}
            </div>
        </div>
    );
};
