import React from 'react';
import { blobUrl } from 'constants/Common';

type NewPasswordLinkSentProps = {
    email: string;
};

const NewPasswordLinkSent = ({ email }: NewPasswordLinkSentProps) => (
    <div style={{ textAlign: 'center' }}>
        <div className="marginBottom" style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={`${blobUrl}/login/email-sent.png`} width="150" />
        </div>
        A new link has been sent to <span className="bold">{email}</span>
    </div>
);

export default NewPasswordLinkSent;