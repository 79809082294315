import React from 'react';

import InputText from '../common/InputText';
import { ErrorMessageBox } from '../common/ErrorMessageBox';
import { Button } from 'components/buttons/Button';
import { TextContentBox } from '../common/TextContentBox';
import { Loading } from 'components/loadings/Loading';

const VerifyCodeView = ({
    activeProvider,
    code,
    errorMessage,
    loading,
    recaptchaFailed,
    onCodeChange,
    onToggleRememberBrowser,
    onVerifyCode,
    rememberBrowser,
}) => {
    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            onVerifyCode();
        }
    };
    return (
        <div className="loginActionsColumn">
            <div className="loginActions twoFactorAuthentication">
                <TextContentBox
                    title="Two-factor Authentication"
                    paragraph1={
                        !recaptchaFailed
                            ? activeProvider === 'Email'
                                ? 'Please enter the authentication code sent to you by email.'
                                : 'Please enter the authentication code you have received on SMS.'
                            : null
                    }
                />
                <div className="tw-mb-24">
                    {recaptchaFailed ? (
                        <ErrorMessageBox
                            errorMessage="Your login attempt was marked as suspicious. As an added security
                                measure, a code has been sent to your registered email address. To
                                complete the login process, please enter that code."
                            variant="warning"
                        />
                    ) : null}
                </div>
                <InputText
                    autoFocus={true}
                    label="Authentication Code"
                    onChange={onCodeChange}
                    onKeyPress={onKeyPress}
                    placeholder="6-digit code"
                    type="number"
                    value={code}
                />
                <div className="tw-flex tw-gap-4 tw-items-center tw-mb-12">
                    <input
                        checked={rememberBrowser}
                        name="rememberBrowserCheckbox"
                        onChange={onToggleRememberBrowser}
                        type="checkbox"
                    />
                    <label
                        htmlFor="rememberBrowserCheckbox"
                        className="tw-text-s"
                        onClick={onToggleRememberBrowser}
                    >
                        Remember this browser for 14 days
                    </label>
                </div>
                <Button onClick={onVerifyCode} text="VERIFY" />
                {errorMessage && (
                    <div className="tw-mb-12">
                        <ErrorMessageBox errorMessage={errorMessage} />
                    </div>
                )}
                {loading && (
                    <div className="twoFactorLoadingContainer">
                        <div className="loading-wrapper -tw-bottom-[100px]">
                            <Loading />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VerifyCodeView;
