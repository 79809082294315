import React, { Component } from 'react';

import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from 'components/theme/ThemeProvider';

import { LoginApp } from './components/Master';
import LoginProvider from './contexts/LoginContext';
import LoginRoutes from './routes';

class AppWrapper extends Component {
    render() {
        return (
            <BrowserRouter>
                <LoginProvider>
                    <ThemeProvider>
                        <LoginApp>
                            <LoginRoutes />
                        </LoginApp>
                    </ThemeProvider>
                </LoginProvider>
            </BrowserRouter>
        );
    }
}

export default AppWrapper;
