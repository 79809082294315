import React from 'react';

import { getCountries, getCountryCallingCode } from 'libphonenumber-js';

const CountryCodeSelect = ({ activeCountry, onChange }) => {
    const countries = getCountries();
    return (
        <div className="phoneCountryWrapper">
            <label className="tw-uppercase tw-tracking-xx-loose tw-text-s tw-font-medium">
                Country
            </label>
            <select
                className="tw-rounded tw-w-full tw-inline-block placeholder:tw-text-neutral-subdued dark:placeholder:tw-text-neutral-dark-subdued tw-text-neutral dark:tw-text-neutral-dark disabled:tw-text-neutral-disabled dark:disabled:tw-text-neutral-dark-disabled tw-bg-neutral dark:tw-bg-neutral-dark disabled:tw-bg-neutral-disabled dark:disabled:tw-bg-neutral-dark-disabled tw-outline-none tw-border tw-border-solid focus:tw-shadow-focus focus:tw-border-primary dark:focus:tw-border-primary-dark tw-border-neutral dark:tw-border-neutral-dark hover:tw-border-neutral-hover dark:hover:tw-border-neutral-dark-hover tw-text-m tw-h-[38px] tw-px-12 tw-py-8"
                name="countryCodeSelect"
                onChange={onChange}
                value={activeCountry}
            >
                {countries.map((country) => {
                    const callingCode = getCountryCallingCode(country);
                    return (
                        <option key={country} value={country}>
                            {country} (+{callingCode})
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default CountryCodeSelect;
