import React from 'react';

import InputText from '../common/InputText';
import InputPassword from '../common/InputPassword';
import { SubmitButton } from '../common/SubmitButton';

const LoginForm = ({
    browser,
    email,
    loading,
    loginFailed,
    onPasswordChange,
    onSignIn,
    password,
}) => {
    const inputBorderClass = loginFailed ? 'warning' : '';
    return (
        <div className="tw-mt-24">
            <form>
                <InputText
                    autoComplete="username"
                    hidden={true}
                    name="username"
                    onChange={() => {}}
                    type="email"
                    value={email}
                />
                <InputPassword
                    autoComplete="current-password"
                    autoFocus={true}
                    browser={browser}
                    className={inputBorderClass}
                    forgotPassword={true}
                    label="Enter your password"
                    name="password"
                    onChange={onPasswordChange}
                    password={password}
                />
                <SubmitButton
                    disabled={loading || password.length === 0}
                    onClick={onSignIn}
                    value="Sign in"
                />
            </form>
        </div>
    );
};

export default LoginForm;
