import React from 'react';

import { Icon } from 'components/icons/Icon';

import { SanityContentElement } from './LoginPageContent';

type LoginPageContentElementProps = {
    content: SanityContentElement;
};

export const LoginPageContentElement = ({ content }: LoginPageContentElementProps) => {
    const { link, title, paragraph, image, category, linkText, videoUrl } = content;
    return (
        <div className="tw-flex tw-items-center tw-flex-col tw-gap-12 tw-py-48 tw-min-w-full">
            <div className="tw-max-w-[848px] tw-w-full tw-px-24">
                <div className="tw-text-neutral-inverse tw-w-full">
                    <div className="tw-font-medium tw-rounded tw-inline-block tw-px-12 tw-py-4 tw-tracking-xx-loose tw-border">
                        {category.substring(0, 1).toUpperCase() + category.substring(1)}
                    </div>
                    <a
                        href={link}
                        target="__blank"
                        rel="noopener noreferrer"
                        className="tw-text-neutral-inverse hover:tw-text-neutral-dark"
                    >
                        <h2 className="lg:tw-text-[36px] tw-text-[26px] tw-font-medium tw-leading-xx-tight tw-mb-8 tw-mt-12">
                            {title}
                        </h2>
                    </a>
                    <p className="lg:tw-text-xl tw-text-neutral-inverse/80">{paragraph}</p>
                    <a
                        className="lg:tw-text-xl tw-font-medium tw-flex tw-items-center tw-gap-4 tw-mt-16 hover:tw-opacity-80 tw-text-[#86d9d6] hover:tw-text-[#86d9d6] tw-group/link"
                        href={link}
                        target="__blank"
                        rel="noopener noreferrer"
                    >
                        {linkText}
                        <span className="group-hover/link:tw-translate-x-4 tw-transition-all">
                            <Icon icon="arrow2-right" size="small" />
                        </span>
                    </a>
                </div>

                {videoUrl ? (
                    <video autoPlay loop muted playsInline className="tw-mt-16 tw-w-full">
                        <source src={videoUrl} type="video/mp4" />
                        <p>
                            Your browser doesn't support HTML5 video. Here is a{' '}
                            <a href={videoUrl}>link to the video</a> instead.
                        </p>
                    </video>
                ) : (
                    <a href={link} target="__blank" rel="noopener noreferrer">
                        <img className="tw-w-full tw-rounded tw-mt-16" src={image} alt={title} />
                    </a>
                )}
            </div>
        </div>
    );
};
