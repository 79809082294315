import React, { Component } from 'react';

import { LoginContext } from '../contexts/LoginContext';
import SendCodeView from '../components/2fa/SendCodeView';

class SendCodeContainer extends Component {
    componentDidMount() {
        const { getTwoFactorProviders, twoFactorProviders } = this.context;
        if (twoFactorProviders.length === 0) {
            getTwoFactorProviders();
        }
    }

    render() {
        const {
            errorMessage,
            loading,
            onTwoFactorSelectProvider,
            onTwoFactorSendCode,
            twoFactorActiveProvider,
            twoFactorProviders,
            twoFactorSendCodeError
        } = this.context;
        return (
            <SendCodeView
                errorMessage={errorMessage}
                loading={loading}
                onTwoFactorSelectProvider={onTwoFactorSelectProvider}
                onTwoFactorSendCode={onTwoFactorSendCode}
                twoFactorActiveProvider={twoFactorActiveProvider}
                twoFactorProviders={twoFactorProviders}
                twoFactorSendCodeError={twoFactorSendCodeError}
            />
        );
    }
}

SendCodeContainer.contextType = LoginContext;

export default SendCodeContainer;