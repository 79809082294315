import React from 'react';

import { Route, Routes } from 'react-router-dom';

import PhoneSuccess from './components/2fa/PhoneNumberSuccessView';
import { Logout } from './components/logout/Logout';
import { SignedInView } from './components/SignedIn';
import AddPhoneNumber from './containers/AddPhoneNumberContainer';
import { FailedSSOContainer } from './containers/FailedSSOContainer';
import ForgotPasswordContainer from './containers/ForgotPasswordContainer';
import { LegacySSORedirectContainer } from './containers/LegacySSORedirectContainer';
import LoginContainer from './containers/LoginContainer';
import ResetPasswordContainer from './containers/ResetPasswordContainer';
import SendCode from './containers/SendCodeContainer';
import VerifyCode from './containers/VerifyCodeContainer';
import VerifyPhoneNumber from './containers/VerifyPhoneNumberContainer';

const LoginRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<LoginContainer />} />
            <Route path="/add-phone-number" element={<AddPhoneNumber />} />
            <Route path="/forgot-password" element={<ForgotPasswordContainer />} />
            <Route path="/phone-success" element={<PhoneSuccess />} />
            <Route path="/reset-password" element={<ResetPasswordContainer />} />
            <Route path="/send-code" element={<SendCode />} />
            <Route path="/set-password" element={<ResetPasswordContainer />} />
            <Route path="/verify-code" element={<VerifyCode />} />
            <Route path="/verify-phone-number" element={<VerifyPhoneNumber />} />
            <Route path="/signed-in" element={<SignedInView />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/sso/failed/:provider" element={<FailedSSOContainer />} />
            <Route path="/sso/legacy/:provider" element={<LegacySSORedirectContainer />} />
            <Route path="*" element={<LoginContainer />} />
        </Routes>
    );
};

export default LoginRoutes;
