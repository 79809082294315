import React from 'react';

type TextContentBoxProps = {
    title?: string;
    paragraph1?: string;
    paragraph2?: string;
};

export const TextContentBox = (props: TextContentBoxProps) => {
    const { title, paragraph1, paragraph2 } = props;
    return (
        <div className={``}>
            {title ? <h1 className="tw-font-bold tw-text-xl tw-m-0">{title}</h1> : null}
            {paragraph1 ? <p className="tw-text-m tw-m-0 tw-mt-4">{paragraph1}</p> : null}
            {paragraph2 ? <p className="tw-text-m tw-m-0 tw-mt-12">{paragraph2}</p> : null}
        </div>
    );
};
