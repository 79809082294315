import React, { useRef, useEffect } from 'react';

import { Link } from 'react-router-dom';

import 'styles/login/inputField.less';

export type InputPasswordProps = {
    autoComplete: string;
    autoFocus?: boolean;
    browser?: string;
    className?: string;
    forgotPassword: boolean;
    hidden?: boolean;
    label?: string;
    name: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    password: string;
};

const InputPassword = ({
    autoComplete,
    autoFocus,
    browser,
    className = '',
    hidden,
    label,
    name,
    onChange,
    password,
}: InputPasswordProps) => {
    const wrapperClasses = ['inputFieldWrapper', 'tw-appearance-none', hidden ? 'hidden' : ''].join(
        ' ',
    );

    const labelText = label ? label : 'Password';
    const inputRef = useRef(null);

    useEffect(() => {
        if (autoFocus && browser !== 'safari') {
            inputRef.current.focus();
        }
    }, []);

    return (
        <div className={wrapperClasses}>
            <label
                className="tw-uppercase tw-tracking-xx-loose tw-text-s tw-font-medium"
                htmlFor={name}
            >
                {labelText}
            </label>
            <input
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                className="tw-appearance-none tw-rounded tw-w-full tw-inline-block placeholder:tw-text-neutral-subdued dark:placeholder:tw-text-neutral-dark-subdued tw-text-neutral dark:tw-text-neutral-dark disabled:tw-text-neutral-disabled dark:disabled:tw-text-neutral-dark-disabled tw-bg-neutral dark:tw-bg-neutral-dark disabled:tw-bg-neutral-disabled dark:disabled:tw-bg-neutral-dark-disabled tw-outline-none tw-border tw-border-solid focus:tw-shadow-focus focus:tw-border-primary dark:focus:tw-border-primary-dark tw-border-neutral dark:tw-border-neutral-dark hover:tw-border-neutral-hover dark:hover:tw-border-neutral-dark-hover tw-text-[18px] tw-h-[40px] tw-px-12 tw-py-8"
                id={name}
                name={name}
                onChange={onChange}
                ref={inputRef}
                spellCheck={false}
                type="password"
                value={password}
            />
        </div>
    );
};

export default InputPassword;
