import React from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { BackToLoginLink } from '../components/common/BackToLoginLink';

export const LegacySSORedirectContainer = () => {
    const { search } = useLocation();
    const { provider } = useParams<{ provider: string }>();
    const newLoginLink = `${window.location.origin}/${provider.toLowerCase()}/login${
        search && search.toLowerCase()
    }`;

    return (
        <div className="tw-w-full">
            {provider && (
                <div className="tw-border tw-rounded tw-p-24">
                    <p className="tw-text-l">
                        The <span className="tw-font-medium">{provider}</span> SSO login link has
                        moved to a new location.
                    </p>
                    <a
                        href={newLoginLink}
                        className="tw-p-8 tw-bg-neutral-variant-2 tw-border tw-rounded tw-w-full tw-mb-24 tw-inline-block"
                    >
                        {newLoginLink}
                    </a>
                    <p>
                        Click the button below to go to the new{' '}
                        <span className="tw-font-medium">{provider}</span> login link
                    </p>
                    <a
                        href={newLoginLink}
                        className="tw-p-8 tw-border tw-rounded tw-w-full tw-mb-12 tw-inline-block tw-text-neutral tw-text-center tw-text-l tw-bg-neutral hover:tw-bg-neutral-hover tw-shadow-xs active:tw-shadow-none active:tw-bg-neutral-active tw-transition-all"
                    >
                        Login with {provider}
                    </a>
                </div>
            )}
            <div className="tw-px-24">
                <BackToLoginLink />
            </div>
        </div>
    );
};
