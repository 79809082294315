import React, { Component } from 'react';

import { LoginContext } from '../contexts/LoginContext';
import VerifyCodeView from '../components/2fa/VerifyCodeView';

class VerifyCodeContainer extends Component {
    render() {
        const {
            errorMessage,
            loading,
            onTwoFactorCodeChange,
            onTwoFactorToggleRememberBrowser,
            onVerifyTwoFactorCode,
            recaptchaFailed,
            twoFactorActiveProvider,
            twoFactorCode,
            twoFactorRememberBrowser
        } = this.context;
        return (
            <VerifyCodeView
                activeProvider={twoFactorActiveProvider}
                code={twoFactorCode}
                recaptchaFailed={recaptchaFailed}
                errorMessage={errorMessage}
                loading={loading}
                onCodeChange={onTwoFactorCodeChange}
                onToggleRememberBrowser={onTwoFactorToggleRememberBrowser}
                onVerifyCode={onVerifyTwoFactorCode}
                rememberBrowser={twoFactorRememberBrowser}
            />
        );
    }
}

VerifyCodeContainer.contextType = LoginContext;

export default VerifyCodeContainer;